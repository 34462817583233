<template>
    <el-popover placement="top" trigger="hover" content="">
        <div>
            <template v-if="type === 'logistics'">
                <div style="font-weight: bold; margin-bottom: 10px">
                    点击按钮后会自动识别您剪切板复制的内容并填入船名、航次、提单号，复制内容的格式需符合以下要求：
                </div>
                <div style="margin-bottom: 10px">
                    <el-row>
                        1.船名与航次之间需用&nbsp&nbsp&nbsp
                        <b>v.</b>
                        &nbsp&nbsp&nbsp
                        <b>V.</b>
                        &nbsp&nbsp&nbsp或&nbsp&nbsp
                        <b>/</b>
                        &nbsp&nbsp 分隔（例如：
                        <b>EVER LIVING/1043E</b>
                        )
                    </el-row>
                    <el-row>
                        2.提单号与船名航次间需用
                        <b>空格</b>
                        分隔（在船名航次之前或之后皆可,例如：
                        <b>EVER LIVING v.1043E NBKN231507</b>
                        ）
                    </el-row>
                </div>
            </template>
            <template v-else-if="type === 'shipSubscrib'">
                <div style="font-weight: bold; margin-bottom: 10px">
                    点击按钮后会自动识别您剪切板复制的内容并填入船名、航次
                </div>
                <div style="margin-bottom: 10px">
                    <el-row>
                        船名与航次之间需用&nbsp&nbsp&nbsp
                        <b>v.</b>
                        &nbsp&nbsp&nbsp
                        <b>V.</b>
                        &nbsp&nbsp&nbsp或&nbsp&nbsp
                        <b>/</b>
                        &nbsp&nbsp 分隔（例如：
                        <b>EVER LIVING/1043E</b>
                        )
                    </el-row>
                </div>
            </template>
            <div>
                注意：若复制格式与要求不符可能导致识别错误，您也可手动输入
            </div>
        </div>
        <div class="smart_input_btn_wrapper" slot="reference">
            <el-button
                style="margin-right: 10px"
                type="text"
                @click="handleSmartInput()"
            >
                智能输入
            </el-button>
        </div>
    </el-popover>
</template>
<script>
import { getClipboardData, getVoyageAndBillNo } from "@/utils";

export default {
    name: "SmartInput",
    props: {
        type: {
            type: String,
            default: "logistics",
        },
    },
    methods: {
        handleSmartInput() {
            getClipboardData()
                .then((res) => {
                    const { billNo, trafName, voyage } =
                        getVoyageAndBillNo(res);
                    if (billNo || trafName || voyage) {
                        this.$emit("smartInput", { billNo, trafName, voyage });
                    } else {
                        this.$message({
                            message: "剪切板内容格式不正确",
                            type: "warning",
                        });
                    }
                })
                .catch(() => {
                    this.$message({
                        message: "当前环境暂不支持获取剪切板内容，请手动输入",
                        type: "warning",
                    });
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.smart_input_btn_wrapper {
    padding-left: 10px;
    &:after {
        content: "";
        height: 2rem;
        margin-top: 12px;
        border-right: 1px solid #ccc;
    }
}
</style>
