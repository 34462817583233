import store from "@/store";
import table from "@/data/table";
import {dropDownMenuQD,dropDownMenuSH,dropDownMenuNB,dropDownMenuTJ,dropDownMenuXM} from "./config/index"
import { timeTypeOptions, timeTypeOptionsSH,timeTypeOptionsQD,timeTypeOptionsTJ,timeTypeOptionsXM } from "@/utils/dataList";
/**
 * @description 物流记录的table，根据不同tab来区分
 * @param  tab NB SH QD
 * @param type 1.当前的store中的记录列表的值 2. 修改当前的store中的历史记录列表的值 3. 原始列表 4.筛选条件(今日) 5.时间类型
 * @param data 修改store中的历史记录列表的值
 */
export const shipTableList = ({ tab, type, data }) => {
    switch (tab) {
        case "NB":
            switch (type) {
                case 1:
                    return [...store.state.table.shiptableList];
                case 2:
                    return store.commit("table/resetShipTableList", data);
                case 3:
                    return [...table.shipTable]
                case 4:
                    return dropDownMenuNB
                case 5:
                    return timeTypeOptions

            }
        case "SH":
            switch (type) {
                case 1:
                    return [...store.state.table.shiptableListSH]
                case 2:
                    return store.commit("table/resetShipTableListSH", data);
                case 3:
                    return [...table.shipTableSH]
                case 4:
                    return dropDownMenuSH
                case 5:
                    return timeTypeOptionsSH
            }
        case "QD":
            switch (type) {
                case 1:
                    return [...store.state.table.shiptableListQD]
                case 2:
                    return store.commit("table/resetShipTableListQD", data);
                case 3:
                    return [...table.shipTableQD]
                case 4:
                    return dropDownMenuQD
                case 5:
                    return timeTypeOptionsQD

            }
        case "TJ":
            switch (type) {
                case 1:
                    return [...store.state.table.shiptableListTJ]
                case 2:
                    return store.commit("table/resetShipTableListTJ", data);
                case 3:
                    return [...table.shipTableTJ]
                case 4:
                    return dropDownMenuTJ
                case 5:
                    return timeTypeOptionsTJ
            }
        case "XM":
            switch (type) {
                case 1:
                    return [...store.state.table.shiptableListXM]
                case 2:
                    return store.commit("table/resetShipTableListXM", data);
                case 3:
                    return [...table.shipTableXM]
                case 4:
                    return dropDownMenuXM
                case 5:
                    return timeTypeOptionsXM
            }
    }
};
