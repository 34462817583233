<template>
    <div v-if="row">
        <span
            v-if="
                !data &&
                row[nameOptions.vesselsName] &&
                row[nameOptions.voyageName] &&
                !row[nameOptions.wharfName]
            "
            @click="addWharf(row)"
            class="table_link_text_bold"
        >
            添加查询码头
        </span>
        <span>
            <i
                @click="addWharf(row)"
                class="el-icon-edit"
                v-if="
                    data &&
                    row[nameOptions.vesselsName] &&
                    row[nameOptions.voyageName] &&
                    !row[nameOptions.wharfName]
                "
            ></i>
            {{ wharfCodeToStr(data) }}
        </span>
        <ShipDialog
            :showTab="showTab"
            :visible.sync="visibleSync"
            v-if="visibleSync"
            title="青岛查询码头修改"
            width="500px"
            size="small"
        >
            <span style="margin-right: 10px">查询码头修改选择:</span>
            <el-select placeholder="请选择码头" v-model="content" filterable>
                <el-option
                    v-for="item in wharfList"
                    :key="item"
                    :label="item"
                    :value="item"
                ></el-option>
            </el-select>
            <DialogFooter
                @confirm="handleTuConfirm"
                @cancel="handleCancel"
                :loading="loading"
                :showTab="showTab"
            ></DialogFooter>
        </ShipDialog>
    </div>
</template>
<script>
import { qdWharfUpdate } from "@/api/qingdaoShipQuery";
import { wharfCodeToStr, getWarfList } from "@/utils";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter";
export default {
    props: {
        row: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: String,
            default: () => "QQCT",
        },
        nameOptions: {
            type: Object,
            default: () => ({
                wharfName: "wharf",
                vesselsName: "vesselsName",
                voyageName: "voyage",
            }),
        },
        showTab: {
            type: String,
            default: () => "QD",
        },
    },
    name: "QDWharfUpdate",
    components: {
        ShipDialog,
        DialogFooter,
    },
    data() {
        return {
            visibleSync: false,
            content: "",
            id: 0,
            loading: false,
            wharfList: [],
            oldWharf: "",
            wharfCodeToStr,
        };
    },
    methods: {
        async addWharf() {
            this.wharfList = await getWarfList();
            this.visibleSync = true;
            let selectVal = this.data;
            if (!this.data) {
                selectVal = "QQCT";
            }
            this.content = selectVal;
            this.oldWharf = selectVal;
        },
        handleCancel() {
            this.visibleSync = false;
        },
        handleTuConfirm() {
            if (this.oldWharf === this.content) {
                this.visibleSync = false;
                return;
            }
            if (!this.content) {
                this.$message({
                    message: "码头不能为空",
                    type: "warning",
                });
                return;
            }
            const param = {
                wharf: this.content,
                vesselsName: this.row[this.nameOptions.vesselsName],
                voyage: this.row[this.nameOptions.voyageName],
            };
            this.loading = true;
            qdWharfUpdate(param)
                .then((res) => {
                    this.$message({
                        message: "修改码头成功",
                        type: "success",
                    });
                    this.$emit("confirm", this.content);
                    this.visibleSync = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped lang="stylus">
.table_link_text_bold {
  color: #409EFF;
  font-weight:bold;
  font-size:14px;
  cursor: pointer;
  user-select: none;
  &:hover{
    opacity: 0.8;
  }
}
</style>
