<template>
    <div class="tabbar-group-wrap">
        <div class="slider-item"></div>
        <div class="tabbar-group">
            <div
                v-for="(item, index) in tabs"
                :key="item.value"
                class="flex-wrap"
            >
                <div
                    :class="{
                        'tabbar-item': true,
                        active: value === item.value,
                        [item.value]: true,
                    }"
                    @click="handleTabClick(item.value)"
                >
                    {{ item.label }}
                </div>
                <div
                    class="divider-line"
                    v-if="index !== tabs.length - 1"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";

const tabs = [
    {
        label: "宁波",
        value: "NB",
    },
    {
        label: "上海",
        value: "SH",
    },
    {
        label: "青岛",
        value: "QD",
    },
    {
        label: "天津",
        value: "TJ",
    },
    {
        label: "厦门(即将上线)",
        value: "XM",
        disable: true,
    },
];

export default {
    name: "TabBarGroup",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        value: {
            type: String,
            default: "NB",
        },
    },
    emits: ["change"],
    data() {
        return {
            tabs,
        };
    },
    watch: {
        value(val) { 
            this.changeSliderPos(val);
        }
    },
    methods: {
        handleTabClick(val) { 
            if (val === 'XM') {
                this.$alert('即将上线，敬请期待！')
            } else { 
                this.$emit('change', val)
            }
        },
        changeSliderPos(val) {
            // 避免实际没更新但是动画执行了的情况
            this.$nextTick(() => {
                if (this.value !== val) {
                    return;
                }
                const curActiveTab = document.getElementsByClassName(val)[0];
                const sliderItem =
                    document.getElementsByClassName("slider-item")[0];
                if (sliderItem && curActiveTab) {
                    anime({
                        targets: `.slider-item`,
                        left: curActiveTab.offsetLeft,
                        top: curActiveTab.offsetTop,
                        height: curActiveTab.clientHeight,
                        width: curActiveTab.clientWidth,
                        duration: 800,
                    });
                    if (sliderItem.classList[1]) {
                        sliderItem.classList.remove(sliderItem.classList[1]);
                    }
                    sliderItem.classList.add(`active-${val}`);
                }
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.changeSliderPos(this.value);
        });
    },
};
</script>

<style lang="stylus">
.tabbar-group-wrap{
    position: relative;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    overflow: hidden;
    .flex-wrap{
        display:flex;
        align-items:center;
    }
    .divider-line{
        width: 1px;
        height: 24px;
        margin: 0 2px;
        background: #cfcfcf;
    }
    .slider-item{
        position: absolute;
        height: 36px;
        background: green;
        z-index: 1;
        border-radius: 4px;
        overflow: hidden;
        &.active-NB{
            background-image: linear-gradient(
                var(--RED-C11C20),
                var(--RED-79031D),
                var(--RED-68020F)
            ) !important;
        }
        &.active-SH{
            background-image: linear-gradient(
                var(--BLUE-3366FF),
                var(--BLUE-5493f4),
                var(--BLUE-006699)
            ) !important;
        }
        &.active-QD{
            background-image: linear-gradient(
                var(--GREEN-006600),
                var(--GREEN-336600),
                var(--GREEN-003300)
            ) !important;
        }
        &.active-TJ{
            background-image: linear-gradient(
                var(--YELLOW-FF9900),
                var(--YELLOW-CC9966),
                var(--YELLOW-CC9933)
            ) !important;
        }
        &.active-XM{
            background-image: linear-gradient(
                var(--PURPLE-330099),
                var(--PURPLE-330066),
                var(--PURPLE-000066)
            ) !important;
        }
    }
    .tabbar-group{
        display: flex;
        align-items: center;
        z-index: 2;
        position: relative;
        height: 100%;
        padding: 4px;
        box-sizing: border-box;
        .tabbar-item{
            font-size: 16px;
            font-weight: bold;
            height: 100%;
            padding: 4px 12px;
            box-sizing: border-box;
            cursor: pointer;
            text-wrap:nowrap;
            &.XM{
                cursor: not-allowed;
                color: #e3dede;
            }
            &.active{
                color: #ffffff;
                background: transparent;
            }
        }
    }
}
</style>
