<template>
    <div class="top_div" :class="`${showTab}_banner`">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "TopBanner",
    props:{
        showTab:{
            type:String,
            default:"NB"
        }
    },
}
</script>
<style scoped>
.NB_banner{
    background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F));
}
.SH_banner{
    background-image: linear-gradient(var(--BLUE-3366FF), var(--BLUE-5493f4), var(--BLUE-006699));
}
.QD_banner{
    background-image: linear-gradient(var(--GREEN-006600), var(--GREEN-336600), var(--GREEN-003300));
}
.TJ_banner{
    background-image: linear-gradient(var(--YELLOW-FF9900), var(--YELLOW-CC9966), var(--YELLOW-CC9933));
}
.XM_banner{
    background-image: linear-gradient(var(--PURPLE-330099), var(--PURPLE-330066), var(--PURPLE-000066));
}
</style>