// 时间快捷操作
export const SHORT_CUTS=[
    {
        text: "今天",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近一周",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(
                start.getTime() - 3600 * 1000 * 24 * 7,
            );
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近一个月",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(
                start.getTime() - 3600 * 1000 * 24 * 30,
            );
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近三个月",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(
                start.getTime() - 3600 * 1000 * 24 * 90,
            );
            picker.$emit("pick", [start, end]);
        },
    },
]