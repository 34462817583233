<template>
    <!-- 设置微信推送弹窗 -->
    <el-dialog
        :visible.sync="dialogTableVisible"
        center
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="setting_dialog_layout"
        attach-to-body
        @confirm="()=>{
            this.$emit('confirm')
        }"
        @close="()=>{
            this.$emit('close')
        }"
        @cancel="()=>{
            this.$emit('cancel')
        }"
    >
        <template slot="title">
            <div class="header-title">
                <div class="id-role">{{ title }}</div>
                <img :src="settingImg" class="setting-img" alt="" width="50" />
                <img :src="popupRight" class="pop-right" alt="" width="250" />
            </div>
        </template>
        <slot></slot>
    </el-dialog>
</template>
<script>
export default {
    props: ["title"],
    data() {
        return {
            logoImg: require("@/assets/img/login/logo.png"),
            popupRight: require("@/assets/img/login/pupup_right.png"),
            settingImg: require("@/assets/img/setting/setting.png"),
            dialogTableVisible: false,
        };
    },
  
    methods: {
        init() {
            this.dialogTableVisible = true;
        },
       
    },
};
</script>
<style lang="stylus" scoped>
.setting_dialog_layout{
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner{
        background-color: #de5d40;
        border-color: #de5d40;
    }
    /deep/ .el-checkbox__label{
        color: #de5d40;
    }
    /deep/ .el-checkbox__input.is-disabled .el-checkbox__inner{
        background-color:#F2F6FC;
        border-color:#DCDFE6;
    }
    .header-title{
        background: linear-gradient(to right, #de5d40, #e5917e, #daacb9);
        height:65px;
        border-radius:10px 10px 0 0;
        position:relative;
        .id-role{
            position:absolute;
            left:80px;
            color:white;
            top:15px;
            font-size:24px;
            display:flex;
            flex-direction:column;
            align-items:flex-start;
        }
        .setting-img{
            left:20px;
            position:absolute
        }
        .pop-right{
            position:absolute;
            right:0;
        }
    }
    /deep/ .el-dialog{
        border-radius: 10px;
    }
    /deep/.el-dialog__header {
        padding: 0
      }
      /deep/.el-dialog__body {
        text-align: initial;
        padding: 30px 25px 30px;
        background:linear-gradient(#ffb4b6, 3%, white, white, white, white) !important;
        border-radius: 10px;
        margin-top: -15px;
      }
      /deep/ .el-checkbox {
        margin-bottom: 1rem;
      }
}
</style>
