<template>
    <div>
        <TopBanner :showTab="showTab">
            <div class="search_div_father">
                <common-header :title="`${showTabTitle}船期跟踪订阅`" :critical-width="1250">
                    <div v-if="tabLayout === 'vertical'" slot="header" style="display: flex; margin-bottom: 8px">
                        <TabBarGroup
                            :value="showTab"
                            @change="showTabClick"
                        ></TabBarGroup>
                    </div>
                    <div class="input_div_father" slot="content">
                        <div v-if="tabLayout === 'horizontal'" style="display: flex; margin-right: 20px">
                            <TabBarGroup
                                :value="showTab"
                                @change="showTabClick"
                            ></TabBarGroup>
                        </div>
                        <!-- 暂时注释 TODO -->
                        <!-- <div class="input_div" v-if="showTab === 'XM'">
                            <el-select
                                placeholder="请选择码头"
                                v-model="XMWharf"
                                filterable
                            >
                                <el-option
                                    v-for="item in XMWharfList"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                            </el-select>
                        </div> -->
                        <div class="input_div">
                            <!-- <smart-input></smart-input> -->
                            <el-autocomplete
                                clearable
                                @clear="blurForBug()"
                                class="inline_input input_with_select"
                                v-model="vesselename"
                                @input="checkInputVesselename()"
                                @change="checkInputVesselename2(vesselename)"
                                :fetch-suggestions="shipSearch"
                                placeholder="请输入船名"
                                :trigger-on-focus="false"
                                @select="handleShipSelect"
                            >
                                <template slot-scope="{ item }">
                                    <div
                                        style="
                                            font-size: 12px;
                                            display: block;
                                            overflow: hidden;
                                            word-break: keep-all;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        "
                                        class="name"
                                    >
                                        {{ item.value }}
                                    </div>
                                </template>
                            </el-autocomplete>
                            <el-input
                                placeholder="请输入航次"
                                v-model="voyage"
                                @keyup.enter.native="getShipWatch()"
                                @input="checkInputVoyage()"
                                class="inline_input input_with_select query_input_center"
                            ></el-input>
                            <el-button
                                :loading="single_add_loading"
                                class="query_input_btn"
                                @click="getShipWatch()"
                                size="small"
                            >
                                添加跟踪 ({{ showTabTitle }})
                            </el-button>
                        </div>
                        <el-button
                            :loading="multi_add_loading"
                            type="primary"
                            style="
                                height: 4rem;
                                margin-left: 12px;
                                font-size: 14px;
                                font-weight: bold;
                            "
                            size="small"
                            @click="uploadHandle()"
                        >
                            批量跟踪 ({{ showTabTitle }})
                        </el-button>
                    </div>
                </common-header>
            </div>
        </TopBanner>
        <div class="data_div">
            <div class="data_search_div">
                <div class="data_search_left_div">
                    <el-popover
                        placement="top"
                        trigger="manual"
                        v-model="popoverVisible"
                        :content="popoverContent"
                    >
                        <div slot="reference" class="search_input_div">
                            <el-dropdown
                                trigger="click"
                                placement="bottom"
                                size="small"
                            >
                                <span class="el-dropdown-link refresh_btn">
                                    {{ selectTypeTitle }}
                                    <i
                                        class="el-icon-arrow-down el-icon--right"
                                    ></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" size="small">
                                    <el-dropdown-item
                                        v-for="(
                                            item, index
                                        ) in dropDownMenuData"
                                        :key="index"
                                    >
                                        <el-radio
                                            :label="item.id"
                                            v-model="portcloseFlag"
                                            @change="selectTypeList"
                                            class="radio_width"
                                        >
                                            {{ item.title }}
                                        </el-radio>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>

                            <el-autocomplete
                                clearable
                                popper-class="el-autocomplete-suggestion vessel-voyage-popper"
                                size="small"
                                :popper-append-to-body="false"
                                @clear="blurForBug()"
                                class="inline_input"
                                v-model="searchVesselenameVoyage"
                                @input="checkSearchVesselenameVoyage()"
                                :fetch-suggestions="querySearch"
                                placeholder="输入船名/航次"
                                :trigger-on-focus="false"
                                @select="handleSelect"
                                @keyup.enter.native="selectInfo()"
                                @change="
                                    (val) => handleInputClear(val, 'vessel')
                                "
                            ></el-autocomplete>
                            <el-button
                                slot="append"
                                icon="el-icon-search"
                                size="small"
                                @click="selectInfo()"
                            ></el-button>
                        </div>
                    </el-popover>
                    <div style="margin-left: 8px" class="search_input_div">
                        <el-select
                            v-model="timeType"
                            clearable
                            size="small"
                            placeholder="请选择"
                            style="width: 130px"
                        >
                            <el-option
                                v-for="item in timeTypeOptionsData"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                        <el-date-picker
                            v-model="searchtime"
                            type="daterange"
                            size="small"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="(val) => handleInputClear(val, 'date')"
                        ></el-date-picker>
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="getVesselsByPage"
                            size="small"
                        ></el-button>
                    </div>
                </div>
                <el-button
                    class="refresh_btn flex_margin_item"
                    size="small"
                    type="success"
                    plain
                    @click="refreshBoxList()"
                >
                    重新载入
                </el-button>
                <el-button
                    class="refresh_btn flex_margin_item"
                    type="danger"
                    plain
                    @click="deleteBoxList()"
                    size="small"
                >
                    批量删除
                </el-button>
                <el-popover
                    placement="top"
                    trigger="click"
                    v-model="downloadPopVisible"
                    class="flex_margin_item"
                >
                    <div class="download_btn_div">
                        <el-button
                            @click="getExcelModel(3)"
                            class="download_btn"
                            type="primary"
                            plain
                        >
                            1、船名航次在同一格
                        </el-button>
                        <el-button
                            @click="getExcelModel(4)"
                            class="download_btn"
                            type="primary"
                            plain
                        >
                            2、船名航次在不同格
                        </el-button>
                    </div>
                    <el-button
                        slot="reference"
                        class="refresh_btn"
                        style="margin-left: 0"
                    >
                        下载模板
                    </el-button>
                </el-popover>
                <column-set
                    :use-system-key="true"
                    :table-list="tableList"
                    page-key="shipSubscribe"
                    :reset-data="{
                        NB: RESETTABLE.shipTable,
                        SH: RESETTABLE.shipTableSH,
                        QD: RESETTABLE.shipTableQD,
                        TJ: RESETTABLE.shipTableTJ,
                        XM: RESETTABLE.shipTableXM,
                    }"
                    :save-mutation="{
                        NB: 'table/editShipTableList',
                        SH: 'table/editShipTableListSH',
                        QD: 'table/editShipTableListQD',
                        TJ: 'table/editShipTableListTJ',
                        XM: 'table/editShipTableListXM',
                    }"
                    :reset-mutation="{
                        NB: 'table/resetShipTableList',
                        SH: 'table/resetShipTableListSH',
                        QD: 'table/resetShipTableListQD',
                        TJ: 'table/resetShipTableListTJ',
                        XM: 'table/resetShipTableListXM',
                    }"
                ></column-set>
                <el-button
                    class="refresh_btn flex_margin_item"
                    @click="showSettingDialog()"
                >
                    微信推送设置
                </el-button>
                <el-button
                    class="refresh_btn flex_margin_item"
                    style="margin-right: 0"
                    @click="handleChangeFixHeader"
                >
                    {{ fixTableHeader ? "关闭固定表头" : "开启固定表头" }}
                </el-button>
            </div>

            <div class="table_div">
                <el-table
                    border
                    stripe
                    v-loading="loading"
                    ref="multipleTable"
                    :key="tableKey"
                    :cell-class-name="timeCellClass"
                    :cell-style="cellCss"
                    :data="boxList"
                    style="width: 100%"
                    :max-height="tableMaxHeight"
                    :row-style="{ height: '36px' }"
                    @selection-change="handleSelectionChange"
                    @header-dragend="handleHeaderChange"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                    }"
                >
                    <el-table-column
                        type="selection"
                        :selectable="selectable"
                        width="55"
                        fixed="left"
                    ></el-table-column>
                    <template v-for="item in tableList">
                        <el-table-column
                            v-if="item.show"
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <span v-if="item.prop === 'place' && item.show">
                                    <PlaceShow :name="showTab" />
                                </span>
                                <span v-if="item.prop === 'boxvv'" class="boxvv-col-wrap">
                                        <span>
                                            {{
                                                scope.row.vesselename +
                                                " " +
                                                "/"
                                            }}
                                        </span>
                                        <span>
                                            {{ " " + scope.row.voyage }}
                                        </span>
                                        <el-dropdown>
                                            <img src="@/assets/icon/copy.svg"/>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-clipboard:copy="scope.row.vesselename" v-clipboard:success="copySucc">复制船名</el-dropdown-item>
                                                <el-dropdown-item v-clipboard:copy="scope.row.voyage" v-clipboard:success="copySucc">复制航次</el-dropdown-item>
                                                <el-dropdown-item v-clipboard:copy="`${scope.row.vesselename}/${scope.row.voyage}`" v-clipboard:success="copySucc">复制全部</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                </span>

                                <span v-else-if="item.prop === 'shipLocation'">
                                    <div
                                        v-if="
                                            scope.row.vesselename &&
                                            scope.row.voyage
                                        "
                                        @click="goShipMap(scope.row)"
                                        style="
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            flex-direction: column;
                                        "
                                    >
                                        <el-image
                                            class="class_ship"
                                            :src="ship"
                                        ></el-image>
                                    </div>
                                </span>
                                <span v-else-if="item.prop === 'remark'">
                                    <p
                                        v-if="!scope.row[item.prop]"
                                        @click="addRemark(scope.row)"
                                        class="add_remark_p"
                                    >
                                        添加备注
                                    </p>
                                    <span v-else>
                                        <i
                                            @click="addRemark(scope.row)"
                                            class="el-icon-edit"
                                        ></i>
                                        {{ scope.row[item.prop] }}
                                    </span>
                                </span>
                                <span
                                    v-else-if="
                                        item.prop.includes('date') ||
                                        item.prop.includes('Date') ||
                                        item.prop.includes('Time')
                                    "
                                >
                                    {{ formatTime(scope.row[item.prop]) }}
                                </span>
                                <span v-else-if="item.prop === 'wharfCode'">
                                    <YGTWharfEdit
                                        :row="scope.row"
                                        :data="scope.row[item.prop]"
                                        :nameOptions="{
                                            wharfName: 'companyid',
                                            vesselsName: 'vesselename',
                                            voyageName: 'voyage',
                                        }"
                                        @confirm="getVesselsByPage"
                                    ></YGTWharfEdit>
                                </span>
                                <span v-else>{{ scope.row[item.prop] }}</span>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column
                        prop="address"
                        fixed="right"
                        label="操作"
                        :formatter="formatter"
                        width="140"
                    >
                        <template slot-scope="scope">
                            <div class="table_control_div">
                                <p
                                    v-if="scope.row.soncount != 0"
                                    class="toTop"
                                    @click="whetherTop(scope.row)"
                                >
                                    {{
                                        scope.row.toptype == "0"
                                            ? "置顶"
                                            : "取消"
                                    }}
                                </p>
                                <p v-if="scope.row.soncount == 0" class="toTop">
                                    -
                                </p>
                                <p
                                    class="detail"
                                    @click="
                                        showDetailDialog(
                                            scope.row,
                                            scope.$index,
                                        )
                                    "
                                >
                                    详情
                                </p>
                                <p
                                    v-if="scope.row.soncount != 0"
                                    class="delete"
                                    @click="deleteBoxItem(scope.row)"
                                >
                                    删除
                                </p>
                                <p
                                    v-else
                                    style="width: 40px; color: rgb(213, 13, 0)"
                                >
                                    -
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination_div">
                <el-pagination
                    background
                    :hide-on-single-page="pageTotal < 10"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="sizes,prev, pager, next ,total"
                    :total="pageTotal"
                    :page-sizes="[15, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    :current-page.sync="pagePosition"
                ></el-pagination>
            </div>
        </div>

        <setting-dialog
            :showTab="showTab"
            v-if="settingDialogVisible"
            ref="SettingDialog"
        ></setting-dialog>
        <login-dialog
            v-if="loginDialogVisible"
            ref="LoginDialog"
        ></login-dialog>
        <delete-box-dialog
            v-if="deleteDialogVisible"
            ref="DeleteBoxDialog"
            @refreshDataList="getVesselsByPage()"
            type="ship"
            :showTab="showTab"
        ></delete-box-dialog>
        <detail-dialog3
            v-if="datailDialogVisible"
            ref="DetailDialog"
            :showTab="showTab"
            @refreshDataList="getVesselsByPage()"
            @addRemark="addRemark()"
            :rows="rows"
        ></detail-dialog3>
        <remark-box-dialog2
            v-if="remarkDialogVisible"
            ref="RemarkBoxDialog"
            @refreshDataList="getVesselsByPage()"
            @getData="getData()"
            :showTab="showTab"
        ></remark-box-dialog2>
        <!-- 弹窗, 上传文件 -->
        <upload
            v-if="uploadVisible"
            ref="upload"
            @refreshDataList="getVesselsByPage()"
            :showTab="showTab"
        ></upload>
    </div>
</template>

<script>
import SmartInput from "@/components/ship/SmartInput";
import ColumnSet from "@/components/common/ColumnSet";
import CommonHeader from "@/components/common/CommonHeader";
import SettingDialog from "./components/PushSettingShip";
import LoginDialog from "@/components/register/WechatLoginDialog";
import DeleteBoxDialog from "@/components/ship/DeleteBoxDialog";
import DetailDialog from "@/components/ship/DetailDialog";
import DetailDialog3 from "./components/DetailDialog3";
import RemarkBoxDialog2 from "@/components/ship/RemarkDialog";
import Upload from "@/components/common/oss-upload";
import TabBarImage from "@/components/common/TabBarImage";
import PlaceShow from "@/components/common/PlaceShow";
import YGTWharfEdit from "@/components/common/YGTWharfEdit";
import { dropDownMenuNB, dropDownMenuSH } from "./config/index";
import {
    setRightForrmatSpace2,
    getMatou,
    setRightForrmatSpace,
    setRightForrmatSpace3,
} from "@/utils/validate";
import { searchShip } from "@/api/boxQuery";
import {
    msToDate,
    freeBalanceLackTip,
    getUserAccountInfo,
    getNameByCode,
    apiCallback,
} from "@/utils";
import { getCompanyId, getRole } from "@/utils/auth";
import { SHORT_CUTS } from "./constants";
import { shipRequest } from "./ship";
import { shipTableList } from "./tableList";
import TopBanner from "@/components/layout/TopBanner";
import { queryXMWharfList } from "@/api/xiamenLogistics";
import TabBarGroup from '@/components/common/TabBarGroup.vue';

export default {
    data() {
        return {
            showTab: "NB",
            activeName: "first",
            msToDate,
            remark: "",
            rows: "",
            icon_green: require("@/assets/img/green.png"),
            icon_red: require("@/assets/img/red.png"),
            ship: require("@/assets/img/chuan1.png"),
            multi_add_loading: false, //批量添加
            single_add_loading: false, //单独添加
            settingDialogVisible: false,
            voyageDialogVisible: false,
            loginDialogVisible: false,
            deleteDialogVisible: false,
            datailDialogVisible: false,
            remarkDialogVisible: false,
            popoverVisible: false,
            uploadVisible: false,
            downloadPopVisible: false,
            popoverContent: "",
            loading: false,
            test: "测试信息",
            loginInterval: "",
            vesselename: "",
            voyage: "",
            blno: "",
            searchVesselename: "", //搜索框船名
            searchVesselenameVoyage: "", //搜索框船名航次
            searchVoyage: "", //搜索框航次
            totalBoxList: [], //存放原始数组用于筛选
            totalVessels: [], //存放原始船名航次用于筛选
            boxList: [], //展示用的列表数据
            multipleSelection: [], //勾选中的列表
            // 表格key
            tableKey: 1,
            pageTotal: 0,
            pageTotalSH: 0,
            pageSize: 15,
            pagePosition: 1,
            startRow: 1,
            nppOptions: [
                {
                    value: "",
                    label: "码头状态",
                },
                {
                    value: "Y",
                    label: "码头已放行",
                },
                {
                    value: "N",
                    label: "码头未放行",
                },
            ],
            customOptions: [
                {
                    value: "",
                    label: "海放状态",
                },
                {
                    value: "Y",
                    label: "海关已放行",
                },
                {
                    value: "N",
                    label: "海关未放行",
                },
            ],
            portcloseFlag: "0", //截关截港筛选，0：全部，1：截关，2：截港
            is_portclose: false, //临近截关期
            is_ctnapplyend: false, //临近截港期
            divlineIndex: 0, //置顶和不置顶的区分线
            customizeRes: [], //自定义ACSII
            showList: [], //需展开的提单号
            searchtime: "", //搜索框时间
            dropDownMenuNB,
            dropDownMenuSH,
            timeType: "",
            isNbSystem: "",
            fixTableHeader: false,
            pickerOptions: {
                shortcuts: [...SHORT_CUTS],
            },
            // 厦门码头列表
            XMWharfList: [],
            // 当前厦门选中的列表
            XMWharf: "",
        };
    },
    components: {
        CommonHeader,
        SettingDialog,
        LoginDialog,
        DeleteBoxDialog,
        DetailDialog,
        DetailDialog3,
        Upload,
        RemarkBoxDialog2,
        ColumnSet,
        SmartInput,
        TabBarImage,
        PlaceShow,
        YGTWharfEdit,
        TopBanner,
        TabBarGroup
    },
    mounted() {
        this.showTab = localStorage.getItem("showTab") || this.showTab;
        this.$nextTick(() => {
            this.Init();
        });
    },
    computed: {
        tableList: {
            get() {
                // if (this.showTab === "SH") {
                //     return this.$store.state.table.shiptableListSH;
                // } else {
                //     return this.$store.state.table.shiptableList;
                // }
                return shipTableList({
                    tab: this.showTab,
                    type: 1,
                });
            },
            set(val) {
                // if (this.showTab === "SH") {
                //     this.$store.commit("table/resetShipTableListSH", val);
                // } else {
                //     this.$store.commit("table/resetShipTableList", val);
                // }
                shipTableList({
                    tab: this.showTab,
                    type: 2,
                    data: val,
                });
            },
        },
        tabLayout() { 
            return this.$store.state.other.screenWidth < 1250 ? 'vertical' : 'horizontal'
        },
        tableMaxHeight() {
            return this.fixTableHeader ? window.innerHeight * 0.57 : "auto";
        },
        selectTypeTitle() {
            const curMenu = this.dropDownMenuData.find(
                (item) => item.id === this.portcloseFlag,
            );
            return curMenu ? curMenu.title : "显示全部";
        },
        dropDownMenuData() {
            return shipTableList({
                tab: this.showTab,
                type: 4,
            });
        },
        timeTypeOptionsData() {
            return shipTableList({
                tab: this.showTab,
                type: 5,
            });
        },
        showTabTitle() {
            return getNameByCode(this.showTab);
        },
        // curPageTotal() {
        //     return this.showTab === "SH" ? this.pageTotalSH : this.pageTotal;
        // },
    },

    watch: {
        tableList: {
            handler(val) {
                this.tableKey += 1;
            },
            deep: true,
        },
    },
    methods: {
        // 厦门获取码头
        getWharfList() {
            queryXMWharfList().then((res) => {
                const data = apiCallback(res);
                this.XMWharfList = data;
            });
        },
        copySucc() {
            this.$message.success('复制成功！')
        },
        resetSearch() { 
            this.searchVesselename = "";
            this.searchVesselenameVoyage = "";
            this.searchVoyage = "";
            this.vesselename = "";
            this.voyage = "";
            this.timeType = "";
            this.searchtime = "";
            this.portcloseFlag = "0";
            this.is_portclose = false;
            this.is_ctnapplyend = false;
            this.pagePosition = 1;
        },
        handleChangeFixHeader() {
            if (this.fixTableHeader === true) {
                this.$confirm(
                    "关闭固定表头需要刷新页面，请确认您的数据已保存！",
                    "提示",
                    {
                        confirmButtonText: "立即关闭",
                        cancelButtonText: "取消",
                        type: "warning",
                    },
                ).then(() => {
                    this.fixTableHeader = !this.fixTableHeader;
                    localStorage.setItem(
                        "fixTableHeader_shipSubscribe",
                        this.fixTableHeader,
                    );
                    window.location.reload();
                });
            } else {
                this.fixTableHeader = !this.fixTableHeader;
                localStorage.setItem(
                    "fixTableHeader_shipSubscribe",
                    this.fixTableHeader,
                );
            }
        },
        // 筛选框清空
        handleInputClear(val, type) {
            if (!val) {
                if (type === "date") {
                    this.timeType = "";
                } else if (type === "vessel") {
                    this.timeType = "";
                    this.searchVesselename = "";
                }
                this.$nextTick(() => {
                    this.getVesselsByPage();
                });
            }
        },
        showTabClick(val) {
            if (this.loading) {
                this.$message.error("请等待数据加载完成再重试，请勿频繁切换");
                return;
            }
            if (val) {
                this.showTab = val;
                this.resetSearch();
                this.Init();
                localStorage.setItem("showTab", val);
                this.$store.commit("other/editSystemKey", val);
            }
        },
        Init() {
            
            const storageFixHeader = localStorage.getItem(
                "fixTableHeader_shipSubscribe",
            );
            this.fixTableHeader = storageFixHeader === "true";
            this.pageSize = this.$store.state.table.pageSize2;
            this.checkLogin();
            var newlen = 0;
            const curShipTableList = shipTableList({
                tab: this.showTab,
                type: 1,
            });
            const resetTableList = shipTableList({
                tab: this.showTab,
                type: 3,
            });
            for (var i = 0; i < curShipTableList.length; i++) {
                if (curShipTableList[i].prop) {
                    newlen += curShipTableList[i].prop.length;
                }
            }
            var oldlen = 0;
            for (var i = 0; i < resetTableList.length; i++) {
                if (resetTableList[i].prop) {
                    oldlen += resetTableList[i].prop.length;
                }
            }
            if (newlen == oldlen) {
                this.tableList = curShipTableList;
            } else {
                this.resetTable();
            }
        },

        // 显示隐藏切换 && 保存表格配置
        saveTableColumns() {
            // setStorage 封装了 localStorage
            shipTableList({
                tab: this.showTab,
                type: 2,
                data: this.tableList,
            });
        },
        resetTable() {
            this.tableKey = 1;
            this.tableList = shipTableList({
                tab: this.showTab,
                type: 3,
            });
            // if (this.showTab === "SH") {
            //     this.tableList = this.RESETTABLE.shipTableSH;
            //     this.tableKey = 1;
            // } else {
            //     this.tableList = this.RESETTABLE.shipTable;
            //     this.tableKey = 1;
            // }
        },
        //定时刷新列表
        refreshListInterval() {},
        //修改分页的pagesize
        handleSizeChange(val) {
            this.pageSize = val;
            this.$store.commit("table/editPageSize2", this.pageSize);
            this.pagePosition = 1;
            this.getVesselsByPage();
        },
        //分页
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.getVesselsByPage();
        },
        //登录，显示二维码弹窗
        login() {
            this.loginDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.LoginDialog.init();
            });
        },
        //定时执行
        checkLogin() {
            var that = this;
            this.loginInterval = setInterval(function () {
                that.checkToken();
            }, 10);
        },
        checkToken() {
            const storageToken = localStorage.getItem("token");
            if (this.$store.state.user.token || storageToken) {
                clearInterval(this.loginInterval);
                this.getVesselsByPage();
                this.getVessels();
                if (this.showTab === "XM") {
                    // 获取码头
                    this.getWharfList();
                }
            }
        },
        //选中的列表数据
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        formatter(row, column) {
            return row.address;
        },
        //重置popver
        resetPopover() {
            this.popoverVisible = false;
            this.popoverContent = "";
        },
        //从列表中模糊匹配
        selectInfo() {
            if (!this.searchVesselenameVoyage) {
                this.popoverVisible = true;
                this.popoverContent = "请输入关键字";
                setTimeout(this.resetPopover, 2000);
                return;
            }
            if (this.searchVesselenameVoyage.length >= 4) {
                if (this.searchVesselenameVoyage) {
                    if (this.searchVesselenameVoyage.split("/").length >= 2) {
                        var list = this.searchVesselenameVoyage.split("/");
                        // this.searchVesselename = function() {
                        var Vesselename = "";
                        list.forEach((item, index) => {
                            if (index == list.length - 1) {
                                return;
                            } else if (index == list.length - 2) {
                                Vesselename = Vesselename + item;
                            } else {
                                Vesselename = Vesselename + item + "/";
                            }
                        });
                        this.searchVesselename = Vesselename;
                        this.searchVoyage = list[list.length - 1];
                    } else {
                        return this.$message.error("请同时输入船名和航次");
                    }
                }
                this.pagePosition = 1;
                this.getVesselsByPage();
            } else {
                this.popoverVisible = true;
                this.popoverContent = "请输入长度至少为4位的关键字";
                setTimeout(this.resetPopover, 2000);
                return;
            }
        },
        //重新载入符合状态的列表
        selectTypeList() {
            this.pagePosition = 1;
            this.getVesselsByPage();
        },
        //重置搜索条件，重新载入列表
        refreshBoxList() {
            this.resetSearch();
            this.getVesselsByPage();
        },

        checkSearchVesselename() {
            if (this.searchVesselename) {
                this.searchVesselename = setRightForrmatSpace(
                    this.searchVesselename,
                );
            }
        },

        checkRightFormat(val) {
            if (val) {
                let codeReg = new RegExp("[A-Za-z-\,]+"), //正则 英文+-；
                    str = "";
                for (var i = 0; i < val.length; i++) {
                    if (codeReg.test(val[i])) {
                        str += val[i].toUpperCase();
                    }
                }
                val = str;
                return val;
            }
        },
        checkSearchVesselenameVoyage() {
            if (this.searchVesselenameVoyage) {
                this.searchVesselenameVoyage = setRightForrmatSpace3(
                    this.searchVesselenameVoyage,
                );
            }
        },
        checkSearchVoyage() {
            if (this.searchVoyage) {
                this.searchVoyage = this.setRightForrmat(this.searchVoyage);
            }
        },
        //全部船名航次搜索框带输入建议
        shipSearch(queryString, cb) {
            var restaurants = this.totalshipList || [];
            var results = queryString
                ? restaurants.filter(this.shipFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        shipFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value.indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //船名航次搜索框带输入建议
        querySearch(queryString, cb) {
            var restaurants = this.totalVessels;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.vesselename
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1 ||
                    restaurant.voyage
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //订阅框船名航次搜索框选择了输入建议
        handleShipSelect(item) {
            var selectShip = item.value.split("/");
            this.vesselename = selectShip[0];
            this.voyage = selectShip[1];
        },
        //船名航次搜索框选择了输入建议
        handleSelect(item) {
            this.searchVesselename = item.vesselename;
            this.searchVoyage = item.voyage;
        },

        checkInputVesselename() {
            if (this.vesselename) {
                this.vesselename = setRightForrmatSpace2(this.vesselename);
            }
            if (this.vesselename.length >= 3) {
                searchShip({ vesselsName: this.vesselename })
                    .then(({ data }) => {
                        this.totalshipList = data.data.map((item) => ({
                            value: `${item.s}/${item.v}`,
                        }));
                    })
                    .catch(() => {
                        this.totalshipList = [];
                    });
            } else if (this.vesselename.length < 3) {
                this.totalshipList = [];
            }
        },
        checkInputVesselename2(val) {
            if (val) {
                var len = val.length - 1;
                if (val[len] == "." || val[len] == "/") {
                    this.vesselename = val.substring(0, len);
                }
            }
        },
        checkInputVoyage() {
            if (this.voyage) {
                this.voyage = this.setRightForrmat(this.voyage);
            }
        },
        //判断输入内容只能是数字和字母，且字母自动转大写
        setRightForrmat(val) {
            if (val) {
                let codeReg = new RegExp("[A-Za-z0-9]+"), //正则 英文+数字；
                    len = val.length,
                    str = "";
                for (var i = 0; i < len; i++) {
                    if (codeReg.test(val[i])) {
                        str += val[i].toUpperCase();
                    }
                }
                val = str;
                return val;
            }
        },

        //获取船名航次用于搜索
        getVessels() {
            const reqFunc = shipRequest({ tab: this.showTab, type: 1 });
            reqFunc({
                code: this.showTab,
            }).then(({ data }) => {
                this.totalVessels = data.data.map(
                    ({ voyage, vesselsName }) => ({
                        vesselename: vesselsName,
                        voyage,
                        value: `${vesselsName}/${voyage}`,
                    }),
                );
            });
        },

        // 订阅船期
        updateShipVoyage() {
            var that = this;
            const param = {
                vesselsName: this.vesselename,
                voyage: this.voyage,
                code: this.showTab,
                companyId: getCompanyId(),
                role: getRole(),
            };
            if(this.showTab==='XM'){
                param.wharf = this.XMWharf;
            }
            const reqFunc = shipRequest({ type: 2, tab: this.showTab });

            reqFunc(param)
                .then(() => {
                    that.getVesselsByPage();
                    that.vesselename = "";
                    this.$message.success("新增成功");
                    that.voyage = "";
                    getUserAccountInfo(this.showTab === "NB");
                })
                .finally(() => {
                    this.single_add_loading = false;
                });
        },

        getShipWatch() {
            // 暂时注释 TODO
            // if(this.showTab==='XM'&&!this.XMWharf){
            //     this.$message.error('请选择码头');
            //     return;
            // }
            if (this.vesselename && this.voyage) {
                this.single_add_loading = true;
                if (!["QD", "TJ", "XM"].includes(this.showTab)) {
                    freeBalanceLackTip(
                        this.showTab,
                        this.updateShipVoyage,
                        null,
                        () => {
                            this.single_add_loading = false;
                        },
                    );
                } else {
                    this.updateShipVoyage();
                }
            } else {
                this.$message.error("请同时输入船名和航次");
            }
        },
        //分页查船期
        getVesselsByPage() {
            let data = {};
            if (this.timeType && !this.searchtime) {
                this.$message.error("请输入搜索日期范围");
                return;
            }
            if (!this.timeType && this.searchtime) {
                this.$message.error("请输入搜索日期类别");
                return;
            }
            var that = this;
            data.code = this.showTab;
            data.current = this.pagePosition;
            data.size = this.pageSize;
            data.vesselsName = this.searchVesselename || undefined;
            data.voyage = this.searchVoyage || undefined;
            data.ctnApplyBeginTime = this.portcloseFlag === "1" ? 1 : null;
            data.ctnApplyEndTime = this.portcloseFlag === "2" ? 1 : null;
            data.closeTime = this.portcloseFlag === "3" ? 1 : null;
            data.arrivalTime = this.portcloseFlag === "4" ? 1 : null; //
            data.departureTime = this.portcloseFlag === "5" ? 1 : null;
            data.queryFlag = this.timeType;
            data.startTime = this.searchtime
                ? this.msToDate(this.searchtime[0]).hasTime
                : undefined;
            data.endTime = this.searchtime
                ? this.msToDate(this.searchtime[1]).hasTime
                : undefined;
            this.loading = true;
            const reqFunc = shipRequest({
                tab: this.showTab,
                type: 6,
            });

            reqFunc(data)
                .then(({ data }) => {
                    const { list, total, startRow, size, pageNum } = data.data;
                    if (list && list.length > 0) {
                        this.totalBoxList = list;
                        this.startRow = startRow;
                        if (size == 0 && that.pagePosition > 1) {
                            //防止最后一页数据删除完后后页面不跳（后端返回的pageNum有问题）
                            that.pagePosition = pageNum - 1;
                            that.getVesselsByPage();
                        } else {
                            that.pagePosition = pageNum;
                        }
                    } else {
                        that.totalBoxList = [];
                        that.boxList = [];
                    }
                    this.pageTotal = total;
                    // if (this.showTab === "SH") {
                    //     this.pageTotalSH = total;
                    // } else {
                    //     this.pageTotal = total;
                    // }
                    that.dealShipList(that.totalBoxList);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        dealShipList(list) {
            this.boxList.splice(0, this.boxList.length);
            list.forEach((item) => {
                var data = {};
                data.arrivaldate = item.arrivalTime || "-"; //实际抵港时间
                data.chndescription = item.chnVesselsName || "-"; //中文船名
                data.companyid = getMatou(item.wharf); //船公司
                data.createTime = item.createTime || "-"; //订阅时间
                data.ctnapplybegindate = item.ctnApplyBeginTime || "-"; //进箱开始时间
                data.ctnapplyenddate = item.ctnApplyEndTime || "-"; //进箱截止时间
                data.customEngdescription = item.customEngdescription;
                data.direction = item.direction; //进出口标记（航向）,I-进口 E-出口
                data.deletedtype = item.deleteType;
                data.dischargedate = item.departureTime || "-"; //实际离港时间
                data.etaArrivedDate = item.etaArrivalTime || "-"; //预计抵港时间
                data.etaSailingDate = item.etaDepartureTime || "-"; //预计离港时间
                data.id = item.id;
                data.remark = item.remark; //备注
                data.toptype = item.topType;
                data.updateTime = item.updateTime || "-";
                data.vesselcode = item.vesselsUn;
                data.vesselename = item.vesselsName;
                data.voyage = item.voyage;
                data.shipCompany = item.shipCompany || "-"; // 船公司
                data.index = this.tableIndex(this.boxList.length); //序号
                if (["NB", "XM"].includes(this.showTab) || !this.showTab) {
                    // 上海船期没有截关时间和截单时间
                    data.closedate = item.closeTime || "-"; //截关时间
                    data.portclosedate = item.portCloseTime || "-"; //截单时间
                }
                if (data.toptype == 1) {
                    this.divlineIndex = data.index - 1;
                }
                if (["QD", "TJ"].includes(this.showTab)) {
                    data.jgApplyBeginTime = item.jgApplyBeginTime; //青岛集港开始(开港)时间
                    data.jgApplyEndTime = item.jgApplyEndTime; // 青岛集港截止时间
                    data.wharfCode = item.wharfCode; // 青岛港查询码头
                    data.wharf = item.wharf; // 青岛港查询码头
                }
                this.boxList.push(data);
            });
        },
        // 打开上传文件组件
        uploadHandle() {
            this.uploadVisible = true;
            let p = {};
            p["type"] = "fileUrl";
            if (this.showTab === "SH") {
                this.$nextTick(() => {
                    this.$refs.upload.initShVessels();
                });
            } else if (this.showTab === "NB") {
                this.$nextTick(() => {
                    this.$refs.upload.initShipSubscrib();
                });
            } else if (this.showTab === "QD") {
                this.$nextTick(() => {
                    this.$refs.upload.initQDVessels();
                });
            } else if (this.showTab === "TJ") {
                this.$nextTick(() => {
                    this.$refs.upload.initTJVessels();
                });
            } else if (this.showTab === "XM") {
                this.$nextTick(() => {
                    this.$refs.upload.initXMVessels();
                });
            }
        },
        //下载Excel模板
        getExcelModel(id) {
            this.downloadPopVisible = false;
            let needDownId=id
            if(this.showTab==='XM'){
                needDownId+=6
            }
            window.open(this.$excel_url + "/" + needDownId, "_blank");
        },

        //添加备注
        addRemark(item = this.rows) {
            this.remarkDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.RemarkBoxDialog.init({
                    id: item.id,
                    vesselename: item.vesselename,
                    voyage: item.voyage,
                    remark: item.remark
                });
            });
        },
        getData() {
            if (this.datailDialogVisible) {
                this.$nextTick(() => {
                    this.$refs.DetailDialog.getData(this.remark);
                });
            }
        },
        //添加船名航次
        addVoyage(item) {
            if (item.soncount == 1) {
                var dataList = [];
                dataList.push(item);
                this.voyageDialogVisible = true;
                this.$nextTick(() => {
                    this.$refs.VoyageChangeDialog.init(dataList);
                });
            } else {
                var dataList = this.totalBoxList.find((value, index, array) => {
                    if (value.blno == item.boxBlno) {
                        return value.sonlist;
                    }
                });
                this.voyageDialogVisible = true;
                this.$nextTick(() => {
                    this.$refs.VoyageChangeDialog.init(dataList.sonlist);
                });
            }
        },
        goHistory() {
            this.$router.push("/history");
        },
        goShipMap(shipInfo) {
            const newpage = this.$router.resolve({
                path: "/shipmap",
                query: {
                    vesselEname: shipInfo.vesselename,
                    voyage: shipInfo.voyage,
                },
                params: { item: shipInfo },
            });
            window.open(newpage.href, "_blank");
        },
        whetherTop(item) {
            var id = item.id;
            if (item.toptype == 0) {
                this.goTotop(id);
            } else if (item.toptype == 1) {
                this.cancelTop(id);
            }
        },
        //置顶
        goTotop(id) {
            const reqFunc = shipRequest({
                tab: this.showTab,
                type: 3,
            });
            reqFunc([id]).then(() => {
                this.$message.success("置顶成功");
                this.getVesselsByPage();
            });
        },
        //取消置顶
        cancelTop(id) {
            const reqFunc = shipRequest({
                tab: this.showTab,
                type: 4,
            });
            reqFunc([id]).then(() => {
                this.$message.success("取消置顶成功");
                this.getVesselsByPage();
            });
        },

        //删除单个订单
        deleteBoxItem(item) {
            const { id, vesselename, voyage } = item;
            const list = [{ id, vesselename, voyage }];
            this.showDeleteDialog(list);
        },
        //批量删除订单
        deleteBoxList() {
            if (this.multipleSelection.length == 0) {
                this.$message.error("请选择要删除的订单");
                return;
            }
            let list = [];
            this.multipleSelection.forEach(({ id, vesselename, voyage }) => {
                list.push({ id, vesselename, voyage });
            });

            this.showDeleteDialog(list);
        },
        showDeleteDialog(list) {
            this.deleteDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.DeleteBoxDialog.init(list);
            });
        },
        showDetailDialog(list, index) {
            this.datailDialogVisible = true;
            this.rows = this.totalBoxList[index];
            this.$nextTick(() => {
                this.$refs.DetailDialog.Init(list);
            });
        },

        go2Detail(itemInfo) {
            const newpage = this.$router.resolve({
                path: "/detail",
                query: {
                    id: itemInfo.id,
                    blno: itemInfo.boxBlno,
                    resultid: itemInfo.resultid,
                },
                params: { item: itemInfo },
            });
            window.open(newpage.href, "_blank");
        },
        showSettingDialog() {
            this.settingDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.SettingDialog.init();
            });
        },

        //表格序号根据分页连续显示
        tableIndex(index) {
            return this.startRow + index;
        },

        //将数据中的字符串转为json
        getString2Json(data) {
            var json = eval("[" + data + "]")[0];
            return json;
        },

        //解决el-autocomplete 点击clearable后再输入无法显示建议列表的bug
        blurForBug() {
            document.activeElement.blur();
        },
        timeCellClass({ row, column, rowIndex, columnIndex }) {
            if (column.property) {
                //若有查验底色,若码放显示不能放行，取消放行等信息，若临近截关期或截港期，底色显示粉色
                if (
                    (column.property === "closedate" &&
                        row.closedate &&
                        this.judgeTimeclose(
                            row.closedate,
                            this.$store.state.ship.portCloseTime,
                            row.dischargedate,
                        )) ||
                    (column.property === "portclosedate" &&
                        row.portclosedate &&
                        this.judgeTimeclose(
                            row.portclosedate,
                            this.$store.state.ship.portCloseTime,
                            row.dischargedate,
                        )) ||
                    (column.property === "ctnapplyenddate" &&
                        row.ctnapplyenddate &&
                        this.judgeTimeclose(
                            row.ctnapplyenddate,
                            this.$store.state.ship.portCloseTime,
                            row.dischargedate,
                        )) ||
                    (column.property === "jgApplyEndTime" &&
                        row.jgApplyEndTime &&
                        this.judgeTimeclose(
                            row.jgApplyEndTime,
                            this.$store.state.ship.portCloseTime,
                            row.dischargedate,
                        ))
                ) {
                    return "red_cell";
                }
            }
            if (column.property) {
                //若有查验底色,若码放显示不能放行，取消放行等信息，若临近截关期或截港期，底色显示粉色
                if (
                    (column.property === "ctnapplybegindate" &&
                        row.ctnapplybegindate &&
                        this.judgeTimetoday(row.ctnapplybegindate)) ||
                    (column.property === "loadTime" &&
                        row.loadTime &&
                        this.judgeTimetoday(row.loadTime)) ||
                    (column.property === "boxvv" &&
                        row.vesselename &&
                        row.voyage &&
                        row.dischargedate &&
                        this.judgeTimetoday(row.dischargedate)) ||
                    (column.property === "jgApplyBeginTime" &&
                        row.jgApplyBeginTime &&
                        this.judgeTimetoday(row.jgApplyBeginTime))
                ) {
                    return "green_cell";
                }
            }
        },
        cellCss({ row, rowIndex }) {
            if (
                row.toptype == 1 &&
                rowIndex != this.boxList.length - 1 &&
                rowIndex == this.divlineIndex
            ) {
                return "border-bottom-color:red";
            }
        },
        // 时间处理切割
        formatTime(time) {
            return time && time !== "-" ? time.slice(5, time.length - 3) : "-";
        },
        //判断时间是否小于设置的时间
        judgeTimeclose(time, markHour, isDepature) {
            const isDepatureValue = isDepature && isDepature !== "-";
            var date = new Date();
            var enddate = new Date(time);
            if (enddate > date) {
                return (enddate - date) / 1000 / 3600 < markHour;
            } else if (!(time && time !== "-")) {
                return false;
            } else {
                return !isDepatureValue;
            }
        },
        //判断时间是否到达今天时间
        judgeTimetoday(time) {
            var date = new Date();
            var enddate = new Date(time);
            return enddate < date;
        },
        selectable: function (row, index) {
            return row.soncount != 0;
        },
        //自定义单元格宽度
        handleHeaderChange(newWidth, oldWidth, column, event) {
            this.tableList.forEach((item) => {
                if (item.label == column.label) {
                    item.width = newWidth;
                }
            });
            this.$store.commit("table/editShipTableList", this.tableList);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.tab{
    width:100px;
    height:42px;
}
#tabber{
    width:100px;
    height:42px;
    margin-left: -7px;
    margin-right: 20px;
}
/deep/ .input_div>.el-input .el-input__inner {
  width:150px
}

/deep/ .input_div>.el-autocomplete .el-input__inner {
  width:320px
}

/deep/ .pink_cell {
  background-color: #FF98A9 !important;
}

/deep/ .green_cell {
  background-color: #C6F3D7 !important;
}
/deep/ .red_cell {
  background-color: #FFB9B9 !important;
}

/deep/input::-webkit-input-placeholder {
  color: #778899;
}

.radio_width {
  width: 100%;

  /deep/ .el-radio__input {
    line-height: 36px;
  }
}

/deep/.el-autocomplete-suggestion {
    width: 300px !important;
}

.search_input_div {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background: #fff;
  align-items: baseline;
  height: 32px;

  // margin: auto;
  .el-input {
    width: 15rem;
  }

  /deep/ .el-dropdown {
    font-size:13px;
  }

  /deep/ .el-input-group__append, .el-input-group__prepend {
    border: none;
  }
}

.refresh_btn {
  height: 32px;
  margin-left: 1rem;
  cursor: pointer;
  // height: 4rem;
}

.p_green {
  color: var(--GREEN-1E9855);
}

.p_red {
  color: var(--RED-C11C20);
}

.el-select {
  width: 12rem;
}

p {
  text-align: center;
  margin: 0;
}

.txt_time {
}

.el-select .el-input {
  width: 130px;
}

.input_with_select .el-input-group__prepend {
  background-color: #fff;
}

.input_div_father {
  display: flex;
  flex-direction: row;
  // margin: auto;
  width: 98%;
  justify-content: center;
}

.input_div {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: none;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.small_title {
  line-height: 1.5;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  user-select: none;
}
.tabs{
    position: absolute;
    top:170px;
    left:0;
}

.search_div_father {
    position: relative;
    z-index:1;
  /deep/ .el-input__inner {
    border: none;
    width: auto;
  }
}

.el-select .el-input {
  width: 13rem;
}

.query_input_center:before, .query-input_right:before {
  content: '';
  // position: absolute;
  height: 2rem;
  margin-top: 12px;
  border-right: 1px solid #ccc;
}

.query_input_btn {
  cursor: pointer;
  height: 4rem;
  padding: 0 12px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 0 4px 4px 0;
  background: #ff9807;
  border: none;
}

.input_with_select .el-input-group__prepend {
  background-color: #fff;
}

.data_div {
  min-height: 55vh;
  margin: 1rem 1rem 0 1rem;
  // margin-left: 6rem;
  // margin-right: 6rem;
  background: #fff;
  padding: 1rem 2rem;
  // border: 1px solid var(--RED-79031D);
  // border-radius: 4px;
}

.table_div {
  min-height: 40rem;
}

// .table_div.divline {

// }
.passIcon {
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
}

.failIcon {
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
}

.data_search_div {
  // margin: 0.5rem 0;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  flex-flow:wrap;

  // background: #fff;
  .data_search_left_div {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    margin-bottom: 10px;
  }
  .flex_margin_item{
    margin: 0 0 0 8px;
  }

  /deep/ .el-button {
    height: 32px;
    padding: 4px 12px;
  }

  /deep/ .el-input-group {
    width: 25rem;
    // margin-left: 1rem;
  }

  /deep/ .el-input__inner {
    // border: 1px solid #DCDFE6;
    border: none;
  }

  /deep/ .el-input--suffix {
    padding: 0;
  }
}

/deep/ .el-table--border th.el-table__cell {
  color: #000 !important;
  text-align: center;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
  font-weight: 700;
}

/deep/ .el-table .cell {
  font-weight: 700;
}

/deep/ .el-table .el-table__cell {
  padding: 2px 0;
  text-align: center;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #f1f3f6;
}

.el-tree {
  padding: 0 2rem 0 0;
}

.tree_btn_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 5px;
}

.flex_div {
  display: flex;
  flex-direction: row;
}

.download_btn_div {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .download_btn {
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}

.pagination_div {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.table_control_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.toTop {
  width: 40px;
  cursor: pointer;
  color: rgb(85, 114, 253);
  user-select: none;
}

.detail {
  width: 40px;
  cursor: pointer;
  color: rgb(85, 114, 253);
  user-select: none;
}

.add_remark_p {
  cursor: pointer;
  color: rgb(85, 114, 253);
  user-select: none;
}

.line {
  width: 2px;
  height: 80%;
  background: var(--GRAY-dcdcdc);
}

.delete {
  width: 40px;
  cursor: pointer;
  color: rgb(213, 13, 0);
  user-select: none;
}

.img_back {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.class_ship {
  width: 28px;
  height: 28px;
}

.model_opt_left_div {
  display: flex;
  flex-direction: row;
}

.model_opt_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
  width: 90px;
  // border-radius: 4px;
  border: 1px solid #EBEEF5;
  background: #fff;

  .el-input {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 4px;
  }

  /deep/ .el-input-group__append, .el-input-group__prepend {
    border: none;
  }

  /deep/ .el-dialog {
    position: fixed;
    width: 380px;
    border-radius: 16rpx;
    top: 20%;
    left: 39.6%;
  }

  /deep/ .el-dialog__body {
    display: flex;
    padding: 30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

// .opt-dialog {
// position: fixed;
// width: 750px;
// border-radius: 16rpx;
// top: 20%;
// left: 30%;

// }

// .input-together-num {
// display: flex;
// flex-direction: row;
// // justify-content: center;
// background-color: #fff;
// border: 2px solid #ccc;
// border-radius: 4px;
// }
.input-together-choose {
  display: flex;
  flex-direction: row;
  width: 268px;
  textarea: 20px;
  // justify-content: center;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.model_choose_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
  // width: 400px;
  margin-top: 20px;
  // border-radius: 4px;
  border: 1px solid #EBEEF5;
  background: #fff;

  /deep/ .el-input-group__append, .el-input-group__prepend {
    border: none;
  }
}

.model_opt_btn {
  display: flex;
  margin: 0 1rem;
  font-size: 15px;
  align-items: center;
  // height: 4rem;
}

.span-class {
  font-family: Microsoft YaHei UI;
  color: #000;
  font-size: 15px;
  // margin-left:30px;
  // margin-right:10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 248px;
}

.addshow_class {
  font-size: 20px;
  float: left;
  // margin-left: 1rem;
}

.reduceshow_class {
  font-size: 30px;
  float: left;
  // margin-left: 1rem;
}

.together_num_class {
  font-size: 10px;
  float: right;
  // margin-right: 2rem;
  // margin-top: 1rem;
}

.item_his_class {
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  align-items: center;
}

/deep/.item_his_class_input .el-input input {
  border-radius: 0px;
  font-size: 15px;
  /* width: 300px; */
}

.multi_btn {
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  // background: #ccc;
  border: 5px;
}

.btn_dialog_daoru {
  width: 52px;
  height: 30px;
  font-size: 12px;
}
.boxvv-col-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        margin-left:4px;
        cursor: pointer;
        margin-top: 5px;
        &:hover{
            opacity: 0.6;
        }
    }
}
</style>
