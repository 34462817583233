<template>
    <ShipDialog
        width="1400px"
        :visible.sync="dialogTableVisible"
        :showTab="showTab"
        center
        @close="closeDialog"
        title="船期信息"
    >
        <DetailContentShip
            :showTab="showTab"
            :shipInfo="shipInfo"
            :boatInfo="boatInfo"
            :boxInfo="boxInfo"
        />
    </ShipDialog>
</template>

<script>
import DetailContentShip from "@/components/ship/DetailContent/DetailContentShip.vue";
import ShipDialog from "@/components/layout/ShipDialog";
export default {
    props: ["rows", "showTab"],
    watch: {
        rows: {
            immediate: true,
            deep: true,
            handler(n, o) {
                // n.vesselEname=
                this.boxList = [];
                this.boxInfo = {};
                this.boatInfo = {};
                this.shipInfo = [];
                this.boxInfo = n;
                this.boxInfo = n;
                this.boatInfo = n;
                this.shipInfo.push(n);
                this.boxList.push(n);
                this.list.push(n);
            },
        },
    },
    data() {
        return {
            dialogTableVisible: false,
            boxInfo: {},
            shipInfo: [],
            boxList: [],
            list: [],
        };
    },
    components: {
        DetailContentShip,
        ShipDialog,
    },
    destroyed() {},
    methods: {
        getData(remark) {
            this.$nextTick(() => {
                this.boxInfo.remark = remark;
                this.keys = Math.random().toString();
            });
        },
        Init(list) {
            this.dialogTableVisible = true;
        },
        //修改备注
        addRemark() {
            this.$emit("addRemark");
        },

        closeDialog() {
            this.$emit("refreshDataList");
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.stepIcon {
  width: 5rem;
  height: 5rem;
  background-size: 100% 100%;
  user-select: none;
}

.passIcon {
  width: 3rem;
  height: 3rem;
  background-size: 100% 100%;
}

.p_time {
  font-weight: 700;
}

p {
  margin: 0;
}

/deep/ .el-tabs__header {
  margin: 0;
}

.detail_div {
  // padding-left: 6rem;
  // padding-right: 6rem;
  margin: auto;
  max-width: 140rem;
}

.bill_div {
  background: #fff;
  padding: 2rem;
  margin: 2rem 0;

  // border: 1px solid var(--RED-79031D);
  // border-radius: 4px;
  .billNo_div {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-content: center;
    align-items: center;
  }

  .billNo_info_div {
    position: relative;
  }
}

.billNo_step_div {
  margin-bottom: 3rem;
  width: 100%;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.step_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot_div {
  margin: 0;
  height: 5rem;
  line-height: 2rem;
  font-size: 4rem;
  color: #ccc;
  user-select: none;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}

.small_title {
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0;

  a {
    user-select: none;
  }
}

.info_title {
  display: block;
  width: fit-content;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0 0 1rem;

  a {
    user-select: none;
  }
}

.info_title:before {
  content: '';
  height: 2rem;
  margin-top: 12px;
  margin-right: 1rem;
  border-left: 2px solid #5572fd;
}

.info_title2 {
  display: block;
  width: fit-content;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 2rem 0 1rem;

  a {
    user-select: none;
  }
}

.info_title2:before {
  content: '';
  height: 2rem;
  margin-top: 12px;
  margin-right: 1rem;
  border-left: 2px solid #5572fd;
}

a {
  cursor: pointer;
  font-weight: 400;
  font-size: 1.4rem;
  color: rgb(85, 114, 253);
}

.flex_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.p_no_margin {
  margin: 1rem 0;
  font-weight: 700;
  font-size: 1.6rem;
}

.p_no_margin2 {
  margin: 0;
  font-weight: 700;
  // font-size:1.6rem;
}

.tab_div {
  display: flex;
  flex-direction: row;
}

.box_div {
  // margin-top: 3rem;
  background: #fff;
  padding: 2rem;
  margin-bottom: 2rem;
  // border: 1px solid var(--RED-79031D);
  // border-radius: 4px;
}

// tabs的箭头自定义
/deep/ .el-tabs__nav-prev {
  background-color: var(--GRAY-dcdcdc);
  width: 4rem;
  height: 4rem;
  border-radius: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-tabs__nav-next {
  background-color: var(--GRAY-dcdcdc);
  width: 4rem;
  height: 4rem;
  border-radius: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-tabs__nav-prev:hover {
  background-color: var(--BLUE-409eff);
}

/deep/ .el-tabs__nav-next:hover {
  background-color: var(--BLUE-409eff);
}

/deep/ .el-tabs__nav-wrap.is-scrollable {
  padding: 0 4rem;
}

/deep/ .el-descriptions-item__label.is-bordered-label {
  font-weight: 700;
  color: #606266;
  background: #eef1f6;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid #dcdcdc;
}

/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #dcdcdc;
}

/deep/ .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
  border-right: 1px solid #dcdcdc;
}

/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #dcdcdc;
}

/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #dcdcdc;
}

/deep/ .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
  border-bottom: 1px solid #dcdcdc;
}

/deep/ .el-table--border, .el-table--group {
  border: 1px solid #dcdcdc;
}

/deep/ .el-table .el-table__cell {
  text-align: center;
}

.p_green {
  color: var(--GREEN-1E9855);
}

.p_red {
  color: var(--RED-C11C20);
}

.back_div {
  background: #f1f3f6;
  border-radius: 4px;
  box-shadow: inset 0 0 15px rgba(55, 84, 170, 0), inset 0 0 20px rgba(255, 255, 255, 0), 10px 10px 18px rgba(55, 84, 170, 0.15), -10px -10px 22px white, inset 0px 0px 4px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  outline: none;
  border: none;
}

// .back_div:hover, .back_div:focus {
// box-shadow: inset 7px 7px 15px rgba(55, 84, 170, 0.15), inset -7px -7px 20px white, 0px 0px 4px rgba(255, 255, 255, 0.2);
// }
.back_div:hover .draw {
  opacity: 0.4;
}

.back_div:hover .prints {
  opacity: 1;
  transition: opacity ease-in-out 0.8s;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/deep/ .el-tabs__item.is-active {
  color: #fff;
  background-color: var(--BLUE-409eff);
}

/deep/ .el-descriptions-item__label.is-bordered-label {
  color: #000;
  // font-size:1.6rem;
}

/deep/ .el-table thead.is-group th.el-table__cell {
  color: #000 !important;
  // font-size:1.6rem;
}

/deep/ .el-table--border th.el-table__cell {
  color: #000 !important;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
  font-weight: 700;
}

/deep/ .el-table .cell {
  font-weight: 700;
}

/deep/ .el-tabs--card>.el-tabs__header {
  // border-bottom: none;
}

/deep/ .el-tabs__item {
  background: var(--GRAY-f1f3f6);
  user-select: none;
  font-weight: 700;
}

.ship_font {
  display: flex;
  font-size: 10px;
  // justify-content: flex-end;
}

/deep/ .pink_cell {
  background-color: #FF98A9 !important;
}

.button_class {
  display: flex;
  justify-content: flex-end;
}
</style>
