import { request } from "@/utils/request.js";

// 查询推送设置
export function pushSetting() {
    return request("/blno/userPushSetting/select", "get");
}

// 更新推送
export function pushSettingUpdate(param) {
    return request("/blno/userPushSetting/update", "post", param);
}
